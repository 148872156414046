<template>
  <div class="_bg-default _80w _100vh d-flex" v-if="forum">
    <div class="_100top pa-3 _60-w">
      <v-btn dark depressed to="/psikolog/personal" color="green" rounded>
        <v-icon>mdi-reply</v-icon> <b>Back</b></v-btn
      >

      <div
        class="d-flex flex-column align-center mt-10"
        v-if="!forum.data.length"
      >
        <img src="../../assets/img/404.svg" height="200px" alt="" />
        <h3>No Forum Available</h3>
      </div>

      <div class="my-3 pb-3" v-for="q in forum.data" :key="q.id">
        <v-card class="radius-card p" @click="toView(q.id)">
          <section
            v-for="(relation, idx) in q.relationships"
            :key="`${idx}-user`"
          >
            <div class="d-flex pa-3" v-if="relation.user">
              <v-avatar class="mr-3">
                <img
                  :src="
                    relation.user.data.photo_profile
                      ? `${env}/upload/photo_profile/${q.user_id}/${relation.user.data.photo_profile}`
                      : `${env}/images/logo.png`
                  "
                  class="img-fit"
                  alt=""
                />
              </v-avatar>
              <div>
                <h4>{{ relation.user.data.nama_lengkap }}</h4>
                <small class="grey--text">{{
                  $date(q.created_at).fromNow()
                }}</small>
              </div>
            </div>
          </section>
          <section class="px-5">
            <h3>{{ q.judul }}</h3>
          </section>
          <v-img
            v-if="q.image"
            :src="`${env}/forum/upload/${q.user_id}/${q.image}`"
            height="250"
            class="img-fit"
          ></v-img>
          <v-card-actions>
            <v-btn text color="blue">
              <v-icon class="mr-1">mdi-eye</v-icon>
              <span v-if="q.views > 0">
                {{ q.views }}
              </span>
            </v-btn>

            <div
              v-for="(relation, idx) in q.relationships"
              :key="`${idx}-${q.id}-like`"
            >
              <v-btn v-if="relation.like" text color="blue">
                <v-icon>mdi-heart</v-icon>
                <span v-if="relation.like.data > 0">
                  {{ relation.like.data }}
                </span>
              </v-btn>
            </div>
            <div
              v-for="(relation, idx) in q.relationships"
              :key="`${idx}-like`"
            >
              <v-btn v-if="relation.comments" text color="blue">
                <v-icon>mdi-comment</v-icon>
                <span v-if="relation.comments.data.length">
                  {{ relation.comments.data.length }}
                </span>
              </v-btn>
            </div>
          </v-card-actions>
        </v-card>
      </div>
      <CreateForum @close="closeDialog" v-bind:dialogForum="dialogForum" />
    </div>
    <section class="pa-2 _100top _40-w">
      <div class="pa-3 d-flex align-center">
        <v-btn dark color="red" class="mr-1" fab depressed small
          ><v-icon>mdi-fire</v-icon></v-btn
        >
        <h3>Most viewed threads</h3>
      </div>

      <div v-if="hotThread">
        <v-card
          class="my-3"
          elevation="1"
          v-for="item in hotThread.data"
          :key="item.id"
          @click="toView(item.id)"
        >
          <div class="px-3 pt-3">
            <h3>{{ item.judul }}</h3>
          </div>
          <v-card-actions>
            <v-btn text color="blue" class="pa-0">
              <v-icon class="mr-1">mdi-eye</v-icon>
              <span v-if="item.views > 0"> {{ item.views }} views </span>
            </v-btn>
          </v-card-actions>
        </v-card>
      </div>
    </section>
  </div>
</template>
c
<script>
import { mapState } from "vuex";
import CreateForum from "../../components/Psycholog/Modal/createForum.vue";

export default {
  components: { CreateForum },
  computed: {
    ...mapState({
      forum: state => state.forum.my_forum,
      hotThread: state => state.forum.hot_thread,
      id: state => state.id,
      env: state => state.API_URL,
      dummy: state => state.dummy
    })
  },

  data() {
    return {
      dialogForum: false
    };
  },
  mounted() {
    this.fetchForum();
    this.fetchHT();
  },
  methods: {
    fetchForum() {
      this.$store.dispatch("forum/myForum");
    },
    fetchHT() {
      let data = {
        page: 1,
        limit: 5
      };
      this.$store.dispatch("forum/hotThread", data);
    },

    closeDialog() {
      this.dialogForum = false;
      this.fetchForum();
    },
    toView(id) {
      this.$router.push(`/psikolog/thread/${id}`);
    }
  }
};
</script>

<style scoped>
._60-w {
  width: 60%;
}
._40-w {
  width: 40%;
}
@media (max-width: 576px) {
  ._60-w {
    width: 100%;
  }
  ._40-w {
    width: 0;
    display: none;
  }
}
@media (max-width: 768px) {
  ._60-w {
    width: 100%;
  }
  ._40-w {
    width: 0;
    display: none;
  }
}
</style>
