import { render, staticRenderFns } from "./myForum.vue?vue&type=template&id=60de56e7&scoped=true&"
import script from "./myForum.vue?vue&type=script&lang=js&"
export * from "./myForum.vue?vue&type=script&lang=js&"
import style0 from "./myForum.vue?vue&type=style&index=0&id=60de56e7&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "60de56e7",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VAvatar } from 'vuetify/lib/components/VAvatar';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VImg } from 'vuetify/lib/components/VImg';
installComponents(component, {VAvatar,VBtn,VCard,VCardActions,VIcon,VImg})
